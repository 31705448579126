import axios from 'axios';
const baseUrl = `${process.env.REACT_APP_REG_URL}`;


export const getLocalFeeByOptionId = (optionId) => 
  axios.get(`${baseUrl}/api/v2/solution-provider/get-local-fee-by-optionId?optionId=${optionId}`);


export const getForeignFeeByOptionId = (optionId) =>
  axios.get(`${baseUrl}/api/v2/solution-provider/get-foreign-fee-by-optionId?optionId=${optionId}`);

export const getGDSNFeeById = (id) =>
  axios.get(`${baseUrl}/api/v2/gdsnfee/${id}`);

export const getSolutionProviderRequest = (regId) =>
  axios.get(`${baseUrl}/api/v2/solution-provider/get-request-by-registration-id?registrationId=${regId}`);

export const getGDSNOperationMode = () =>
  axios.get(`${baseUrl}/api/v2/gdsnoperationmode`);


export const getGDSNReceiver = () =>
  axios.get(`${baseUrl}/api/v2/gdsnreceiver`);

export const createGDSNInfo = (payload) =>
  axios.post(`${baseUrl}/api/v2/gdsnrequest`, payload);

export const createSolutionProviderRequest = (payload) =>
  axios.post(`${baseUrl}/api/v2/solution-provider/create-solution-provider-request`, payload);

export const getCoreCompetences = () =>
  axios.get(`${baseUrl}/api/v2/solution-provider/get-all-core-competences`);

export const getSolutionProviderById = (id) =>
  axios.get(`${baseUrl}/api/v2/solution-provider/get-option-by-id?id=${id}`);

export const getCompetenceById = (id) =>
  axios.get(`${baseUrl}/api/v2/solution-provider/get-competence-by-id?id=${id}`);

export const getAllOptions = () =>
  axios.get(`${baseUrl}/api/v2/solution-provider/get-all-options`);

export const sendConfirmationEmail = (payload) =>
  axios.post(`${baseUrl}/api/v2/company/sendconfirmationemail`, payload);